<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card :class="isMobile ? 'pa-0 ma-0': 'pl-2 pr-2' " elevation="0" tile>
          <v-tabs
            v-model="activeTab"
            background-color="transparent"
          >
            <v-tab @click="changePath('waiting')" class="font-weight-bold text-capitalize">
              Baru
            </v-tab>
            <v-tab @click="changePath('process')" class="font-weight-bold text-capitalize">
              Dalam Proses
            </v-tab>
            <v-tab @click="changePath('resolved')" class="font-weight-bold text-capitalize">
              Selesai
            </v-tab>
          </v-tabs>
          <v-divider style="margin-top:-2px" />
        </v-card>
      </v-col>
    </v-row>
    <v-tabs-items v-model="activeTab">
      <v-tab-item>
        <ListNew
          :key="key.new"
          @fetching="fetching"
        />
      </v-tab-item>
      <v-tab-item>
        <ListProcess
          :key="key.process"
          @fetching="fetching"
        />
      </v-tab-item>
      <v-tab-item>
        <ListResolved
          :key="key.resolved"
          @fetching="fetching"
        />
      </v-tab-item>
    </v-tabs-items>
    <FooterShippingProblem/>
  </v-container>
</template>

<script>

import FooterShippingProblem from './FooterShippingProblem.vue';
import ListNew from './List/New.vue';
import ListProcess from './List/Process.vue';
import ListResolved from './List/Resolved.vue';

export default {
  name: 'index',
  components: {
    FooterShippingProblem,
    ListNew,
    ListProcess,
    ListResolved,
  },
  created() {
    const { status } = this.$route.params;
    this.setActiveTab(status);
  },
  data() {
    return {
      path: '',
      activeTab: 0,
      key: {
        new: 0,
        process: 0,
        resolved: 0,
      },
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    currentPath() {
      return this.$route.path;
    },
  },
  watch: {
    currentPath() {
      const { status } = this.$route.params;
      this.setActiveTab(status);
    },
  },
  methods: {
    fetching(tab) {
      this.key[tab] += 1;
    },
    changePath(path) {
      if (this.path.localeCompare(path) !== 0) {
        this.path = path;
        this.$router.replace(`/main/masalah/list/${path}`);
      }
    },
    setActiveTab(status) {
      switch (status) {
        case 'waiting':
          this.path = 'waiting';
          this.activeTab = 0;
          break;
        case 'process':
          this.path = 'process';
          this.activeTab = 1;
          break;
        default:
          this.path = 'resolved';
          this.activeTab = 2;
          break;
      }
    },
  },
};
</script>
