<template>
  <v-container class="pa-0" fluid>
    <FilterShippingProblem
      orderBy="Dalam Proses"
      :setFilter="setFilter"
    />
    <v-sheet class="mb-5" elevation="1">
      <br>
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="cargoTitle"
        class="body"
        @click:row="rowClicked"
        :server-items-length="totalItems"
        :options.sync="pagination"
        :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
        :loading="isLoading"
        :loading-text="$_strings.common.LOADING_TEXT"
      >
        <template v-slot:[`item.isCompleteByAdmin`]="{item}">
          <v-icon
            color="yellow accent-4"
            v-if="item.isCompleteByAdmin === false"
          >
            mdi-flag-triangle
          </v-icon>
          <v-icon
            color="red"
            v-else
          >
            mdi-flag-triangle
          </v-icon>
        </template>
        <template v-slot:[`item.accidentReason`]=items>
          <span>{{ items.item.accidentReason }}</span>
        </template>
        <template v-slot:[`item.shipmentGroupTitle`]=items>
          <span>{{ items.item.shipmentGroupTitle }}</span>
          <p class="ma-0">{{dateFormat(items.item.createdAt) }}</p>
        </template>
        <template v-slot:[`item.cargoTitle`]=items>
          <span>{{ items.item.cargoTitle }}</span>
        </template>
        <template v-slot:[`item.transporterName`]=items>
          <span>{{ items.item.transporterName }}</span>
          <br/>
          <span>{{ items.item.rekanan3PL }}</span>
        </template>
        <template v-slot:[`item.transportTypeName`]=items>
          <span>{{ items.item.transportTypeName }}</span>
          <br/>
          <span>{{ items.item.companyTransportNo }}</span>
        </template>
        <template v-slot:[`item.companyDriverName`]=items>
          <span>{{ items.item.companyDriverName }}</span>
          <br/>
          <span>{{ items.item.companyDriverPhone }}</span>
        </template>
        <template v-slot:[`item.action`]="{item}">
          <v-btn
            color="primary"
            class="mr-2 ml-2"
            small
            @click="($event) => setDialogDoneProblem($event, item)"
            v-if="userAccess.canUpdate"
          >
            {{$_strings.shippingProblem.selesaikan}}
          </v-btn>
        </template>
        <template v-slot:[`footer.page-text`]="props">
          <span>
            {{$_strings.order.PAGE_NAME}}
            <span v-if="items.length">
              {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
            </span>
          </span>
        </template>
      </v-data-table>
    </v-sheet>
    <DialogDoneProblem
      ref="dialogDoneProblem"
      @fetchShippingProblem="fetchShippingProblem"
    />
  </v-container>
</template>

<script>
import {
  dateFormat,
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';
import FilterShippingProblem from '../FilterShippingProblem.vue';
import DialogDoneProblem from '../Dialog/DoneProblem.vue';

export default {
  name: 'order-list-process',
  components: {
    FilterShippingProblem,
    DialogDoneProblem,
  },
  data() {
    return {
      isLoading: false,
      dialogDoneProblem: false,
      temporary: {},
      filter: {
        search: this.$route.query.search || '',
        accidentReason: this.$route.query.accidentReason || '',
      },
      items: [],
      headers: [
        {
          text: '',
          value: 'isCompleteByAdmin',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.shippingProblem.headerMasalah,
          value: 'accidentReason',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.shippingProblem.headerNokiriman,
          value: 'shipmentGroupTitle',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.shippingProblem.headerNoPesanan,
          value: 'cargoTitle',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.shippingProblem.headerRekanan3PL,
          value: 'transporterName',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.shippingProblem.headerKendaraan,
          value: 'transportTypeName',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.shippingProblem.headerSopir,
          value: 'companyDriverName',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.shippingProblem.headerAksi,
          value: 'action',
          class: 'white--text primary text-capitalize',
        },
      ],
      pagination: defaultPagination(),
      totalItems: 0,
      historyPath: null,
    };
  },
  watch: {
    $route: function setHistoryPath(newVal, oldVal) {
      if (oldVal.params.status === 'process') {
        this.historyPath = oldVal.fullPath;
      }
    },
    '$route.path': function setQueryString() {
      const { status } = this.$route.params;
      const { query } = this.$route;
      if (status === 'process' && !Object.keys(query).length) {
        this.$router.replace(this.historyPath);
      }
    },
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        this.fetchShippingProblem();
      },
      deep: true,
    },
  },
  methods: {
    dateFormat,
    toogleLoading(boolean) {
      if (this.items.length > 0) {
        this.isLoading = false;
        return boolean ? this.$root.$loading.show() : this.$root.$loading.hide();
      }
      this.$root.$loading.hide();
      this.isLoading = boolean;
    },
    fetchShippingProblem() {
      this.toogleLoading(true);
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const status = 'PROCESS';
      const size = itemsPerPage;
      const sort = handleSortBy({ defaultValue: 'createdAt,DESC', sortBy, sortDesc });
      const { search, accidentReason } = this.filter;
      this.$_services.shippingProblem.getListShippingProblem(skipEmptyStringObject({
        page: page - 1, size, sort, search, accidentReason,
      }), status)
        .then((res) => {
          this.items = res.data.contents;
          this.totalItems = res.data.totalData;
        })
        .finally(() => {
          this.toogleLoading(false);
        });
    },
    setFilter(filterBy, value) {
      this.filter[filterBy] = value;
      if (this.$route.query[filterBy] !== value) {
        this.$router.replace(({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            [filterBy]: value,
            page: 1,
          },
        }));
      }
      this.$emit('fetching', 'process');
    },
    setDialogDoneProblem(event, item) {
      this.$refs.dialogDoneProblem.dialog = true;
      this.$refs.dialogDoneProblem.item = item;
      event.stopPropagation();
    },
    async rowClicked(e, { item }) {
      if (this.dialogDoneProblem === true) return;
      try {
        this.$root.$loading.show();
        const result = await this.$_services.shippingProblem.getDetailShippingProblem(item.cargoAccidentId);
        this.$router.push({
          name: 'masalah-detail',
          params: {
            id: item.cargoAccidentId,
            data: result.data,
          },
          query: {
            shipmentGroupTitle: item.shipmentGroupTitle,
            status: 'Process',
          },
        });
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
