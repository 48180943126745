<template>
  <v-sheet elevation="1">
    <v-row class="pl-4 caption align-center">
      <v-col cols="auto"><span>Legend:</span></v-col>
      <v-col cols="auto">
        <v-icon color="yellow">
          mdi-flag-triangle
        </v-icon>
        <span class="ml-2">{{$_strings.shippingProblem.handledByDriver}}</span>
      </v-col>
      <v-col cols="auto">
        <v-icon color="red">
          mdi-flag-triangle
        </v-icon>
        <span class="ml-2">{{$_strings.shippingProblem.handledByAdmin}}</span>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>

export default {
  name: 'footer-order',
};
</script>
<style lang="scss" scoped>
</style>
